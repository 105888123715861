.Footer-container{
    position: relative;
}
.Footer-container hr{
    border: 2px solid white;
}
.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.sociallink{
    display: flex;
    gap: 4rem;
}
.sociallink>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
.blur1{
    width: 45rem;
    height: 30rem;
    left: 20%;
}
