.Joinus{
    display: flex; 
    padding-left: 2rem;
}
.left-j{
    color: white;
    font-size: 3.5rem;
    font-weight: bold;
    text-transform: uppercase;
    position:relative;
}
.left-j>hr{
    border: 3px solid orange;
    border-radius: 20%;
    width: 15rem;
    position: absolute;
    margin: -10px 0;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-left: 9rem;
}
.container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    width:100%;
    background-color: grey;
    padding: 2rem 4rem 2rem 4rem;
    border: none;
    border-radius: 1.5rem;
}
.container>div{
    width: 100%;
    border: none;
    border-radius: 20px;
}
.container>div>input{
    background: transparent;
    width: 15rem;
    padding: 20px;
    border-radius: 1rem;
    border: none;
    outline: none;
}
.container>div>input:hover{
    background: rgb(163, 157, 157);
}
::placeholder{
    color: var(--lightgrey);
    font-size:large;
}

.btn-j{
    color: white;
    background-color: var(--orange);

}