.Plan-container{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.plan-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-size: 3rem;
    color: white;
    justify-content: center;
    font-style: italic;
}
.plans{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}

.plan>svg{
    fill: white;
    height: 2rem;
    width:2rem ;
}
.plan>:nth-child(2){
    font-size: 1rem;
    font-weight: bold;
}
.plan>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.plan>:nth-child(5){
    font-size: 0.8rem;
}
.features{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.btn{
    width: 100%;
}
.feature img{
    width: 1rem;
}
.plans>:nth-child(2)>svg{
    fill: white;
}
.plans>:nth-child(2)>button{
    color: orange;
}
.plan-blur1{
    width: 15rem;
    height: 25rem;
    left: 0;
}
.plan-blur2{
    width: 20rem;
    height: 25rem;
    right: 0;
}
.plan:hover{
    background: var(--planCard);
    transform: scale(1.1);
}













