.reason{
    display: flex;
    padding: 0 2rem;
    grid-template: 2rem;
}
.left-s{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}
.left-s img{
    object-fit: cover;
}
.left-s>:nth-child(1){
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}
.left-s>:nth-child(2){
    width: auto;
    height: 16rem;
    grid-column: 2/4;
}
.left-s>:nth-child(3){
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
}
.left-s>:nth-child(4){
    grid-column: 3/4;
    width: 10rem;
    grid-row: 2;
    height: 11.2rem;
}
.right-s{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}
.right-s>span{
    font-weight: bold;
    color: var(--orange);
}
.right-s>div{
    font-size: 3rem;
    color: white;
    font-weight: bold;
}
.detail-s{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.detail-s>div{
    display: flex;
    gap: 1rem;
    font-size: 1.5rem;
}
.detail-s>div>img{
    width: 2rem;
    height: 2rem;
}
#partner{
    padding-top: 2rem;
    color: grey;
    font-size: medium;
}
.partner{
    display: flex;
    gap: 1rem;
    
}