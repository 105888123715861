.Testimonial{
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
}
.left-t{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    color: white;
    gap: 2rem;
    text-transform: uppercase;
}
.left-t>:nth-child(1){
    color: var(--orange);
    font-weight: bold;
}
.left-t>:nth-child(2),.left-t>:nth-child(3){
    font-size: 3rem;
    font-weight: bold;
}
.left-t>:nth-child(4){
    text-transform: none;
    text-align: justify;
    line-height: 40px;
    letter-spacing: 2px;
}
.right-t{
    flex: 1;
    position: relative;
}
.right-t>img{
    position: absolute;
    width: 17rem;
    height: 20rem;
    object-fit: cover;
    right: 8rem;
    top: 2rem;
}
.right-t>:nth-child(1){
    position: absolute;
    width: 17rem;
    height: 20rem;
    border: 2px solid var(--orange);
    background: transparent;
    right:9rem ;
    top: 0.9rem;
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17rem;
    height: 19rem;
    background: var(--planCard);
    right:7rem ;
    top: 4rem;
}
.arrow{
    display: flex;
    position: absolute;
    left: 55%;
    gap: 1rem;
}
.arrow>img{
    width: 1.5rem;
    cursor: pointer;
}
