.hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    padding: 2rem;
    padding: 1.5rem;
    flex: 3;
    display: flex;
    gap:2rem;
    flex-direction: column;
}
.right-h{
    flex: 1;
    background-color: var(--orange);
}
.best-ad{
    background-color: #363d42;
    border-radius: 4rem;
    margin-top: 4rem;
    color: white;
    width: fit-content;
    padding: 1rem 1rem;
    display: flex;
    position: relative;
    align-items: center;
}
.best-ad span{
    z-index: 2;
}
.best-ad div{
    background-color: var(--orange);
    border-radius: 4rem;
    height: 80%;
    width: 5rem;
    position: absolute;
    left: 8px;
    z-index: 1;
}
.hero-title{
    font-size: 4.5rem;
    color: white;
    font-weight: bolder;
    margin-left: 10px;
}
.hero-title2{
    font-size: 4.5rem;
    color: white;
    font-weight: bolder;
    margin-left: 10px;
}
.description{
    padding-left: 10px;
    font-size: larger;
    color: white;
    font-weight: bolder;
}
.count{
    display: flex;
    gap: 3rem;
}
.count div{
    display: flex;
    flex-direction: column;    
}
.count div span:nth-of-type(1){
    font-size: 2rem;
    font-weight: bolder;
    color: white;
    padding-left: 10px;
}
.count div span:nth-of-type(2){
    font-size: 1.5rem;
    color: black;
}
.hero-button{
    display: flex;
    gap: 1rem;
    font-weight: normal;
    font-size: xx-large;
}
.hero-button>:nth-child(1){
    color: white;
    background-color: var(--orange);
    width: 10rem;
    height:3.5rem;
}

.hero-button>:nth-child(2){
    color: white;
    background-color: transparent;
    width: 10rem;
    height:3.5 rem;
    border: 2px solid var(--orange);
}

.right-h .btn{
    font-weight: bolder;
    font-size: large;
    padding: 0.5rem;
    background-color: transparent;
    margin-left: 6rem;
    margin-top: 1.8rem;
    color:var(--grey);
    width: 10rem;
    height: 4rem;
    border: 3px solid #363d42;
    border-radius: 3px;
}
.right-h .btn:hover{
    color: white;
    background-color: #363d42;
}
.heroImage{
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 24rem;
    z-index: 2;
}
.herobackground{
    position:absolute;
    top: 6rem;
    right: 24rem;
    width: 15rem;
    z-index: 1;
}
.tick{
    position: absolute;
    top: 23rem;
    right: 45rem;
    width: 13rem;
    z-index: -1;
}
.hero-blur{
    width: 22rem;
    height:30rem;
    left: 0;
}